<template>
  <div class="bg-image">
    <div class="container">
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true">
      </b-loading>
      <div class="column is-half is-offset-one-quarter">
        <div class="title columns has-text-centered has-text-white">
          <div class="column is-12 is-size-2">KONTO BIZNESOWE</div>
        </div>
        <div class="title columns has-text-centered has-text-white">
          <div class="column is-12 is-size-4">wirtualne biuro</div>
        </div>
        <div class="card">
          <div class="card-content">
            <div class="is-centered">
              <div class="is-flex is-justify-content-center">
                <figure @click="$router.push('/')">
                  <img
                    src="./../../assets/img/home/icons/business_account.png"
                  />
                </figure>
              </div>
            </div>

            <div class="columns">
              <div class="column is-full">
                <div class="columns is-multiline">
                  <div class="column is-full">
                    <p class="panel panel-heading is-primary">
                      Dane podstawowe
                    </p>
                  </div>
                  <div class="column is-full">
                    <b-field
                      label="Nazwa firmy"
                      :type="nameFieldType"
                      :message="nameRules"
                    >
                      <b-input
                        v-model="form.name"
                        @input="$v.form.name.$touch()"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-full">
                    <b-field
                      label="Nazwa użytkownika"
                      :type="usernameFieldType"
                      :message="usernameRules"
                    >
                      <b-input
                        v-model="form.username"
                        @input="$v.form.username.$touch()"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-full">
                    <b-field
                      label="Email"
                      :type="emailFieldType"
                      :message="emailRules"
                    >
                      <b-input
                        type="email"
                        v-model="form.email"
                        @input="$v.form.email.$touch()"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-full">
                    <b-field
                      label="Hasło"
                      :type="passwordFieldType"
                      :message="passwordRules"
                    >
                      <b-input
                        type="password"
                        v-model="form.password"
                        @input="$v.form.password.$touch()"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-full">
                    <b-field
                      label="Powtórz hasło"
                      :type="passwordRepeatFieldType"
                      :message="passwordRepeatRules"
                    >
                      <b-input
                        type="password"
                        v-model="form.passwordRepeat"
                        @input="$v.form.passwordRepeat.$touch()"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-full"></div>
                  <div class="column is-full">
                    <p class="panel panel-heading is-primary">Dane adresowe</p>
                    <b-field
                      label="Miejscowość"
                      :type="cityFieldType"
                      :message="cityRules"
                    >
                      <b-input
                        type="text"
                        v-model="form.city"
                        @input="$v.form.city.$touch()"
                      ></b-input>
                    </b-field>
                    <b-field
                      label="Ulica"
                      :type="streetFieldType"
                      :message="streetRules"
                    >
                      <b-input
                        type="text"
                        v-model="form.street"
                        @input="$v.form.street.$touch()"
                      ></b-input>
                    </b-field>
                    <b-field
                      label="Nr domu/lokalu"
                      :type="localNbFieldType"
                      :message="localNbRules"
                    >
                      <b-input
                        type="text"
                        v-model="form.localNb"
                        @input="$v.form.localNb.$touch()"
                      ></b-input>
                    </b-field>
                    <b-field
                      label="Kod pocztowy"
                      :type="postcodeFieldType"
                      :message="postcodeRules"
                    >
                      <b-input
                        v-model="form.postcode"
                        @input="$v.form.postcode.$touch()"
                        :rules="{ regex: /^[0-9]{2}-[0-9]{3}$/ }"
                        placeholder="00-000"
                        size="6"
                        maxlength="6"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-full">
                    <p class="panel panel-heading is-primary">Pozostałe dane</p>
                    <b-field
                      label="Opis firmy"
                      :type="descriptionFieldType"
                      :message="descriptionRules"
                    >
                      <b-input
                        v-model="form.description"
                        @input="$v.form.description.$touch()"
                      ></b-input>
                    </b-field>
                    <b-field
                      label="REGON"
                      :type="regonFieldType"
                      :message="regonRules"
                    >
                      <b-input
                        v-model="form.REGON"
                        @input="$v.form.REGON.$touch()"
                        pattern="(^[0-9]{9}$)|(^[0-9]{14}$)"
                      ></b-input>
                    </b-field>
                    <b-field
                      label="NIP"
                      :type="nipFieldType"
                      :message="nipRules"
                    >
                      <b-input
                        v-model="form.NIP"
                        @input="$v.form.NIP.$touch()"
                        pattern="(^[0-9]{10}$)"
                        maxlength="10"
                      ></b-input>
                    </b-field>
                    <b-field
                      label="Nr telefonu"
                      :type="phoneFieldType"
                      :message="phoneRules"
                    >
                      <b-input
                        v-model="form.phone"
                        @input="$v.form.phone.$touch()"
                        pattern="(^[0-9]{9}$)|(^[0-9]{11}$)"
                        placeholder="48000000000 lub 000000000"
                        size="9"
                        maxlength="12"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-full">
                    <b-field :type="policiesFieldType" :message="policiesRules">
                      <b-checkbox v-model="form.policies"
                        >Akceptuję
                      <a class="is-primary" href="docs/regulamin.pdf">regulamin</a>,
                      <a class="is-primary" href="docs/polityka_prywatnosci.pdf">politykę prywatności</a>
                      i
                      <a class="is-primary" href="docs/formularz_odstapienia.pdf">warunki odstąpienia</a></b-checkbox>
                    </b-field>
                  </div>
                  <div class="column is-full">
                    <b-button
                      type="is-primary"
                      @click="register"
                      rounded
                      class="is-fullwidth"
                      >Zarejestruj</b-button
                    >
                  </div>
                  <div
                    class="column is-full"
                    v-if="response.status != null && response.status"
                  >
                    <!-- 201 -->
                    <b-message type="is-success" title="Konto utworzone">
                      {{ response.content }}
                      <br />
                      <router-link :to="{ name: 'CompanyLogin' }">
                        <center>Zaloguj</center>
                      </router-link>
                    </b-message>
                  </div>
                  <div
                    class="column is-full"
                    v-else-if="
                      response.status != null && response.status != 201
                    "
                  >
                    <b-message
                      type="is-danger"
                      title="Wystąpił błąd w czasie zakładania konta"
                    >
                      "Nie można utworzyć konta w serwisie. Jeśli problem będzie
                      się powtarzał skontaktuj się z administratorem serwisu."
                      {{ response.content }}
                    </b-message>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card"></div>
    </div>
  </div>
</template>
<script>
import {
  required,
  email,
  sameAs,
  minLength,
  alphaNum,
  helpers,
} from "vuelidate/lib/validators";

const polishAlpha = helpers.regex(
  "polishAlpha",
  /^[a-zA-Z\u0105\u0104\u0107\u0106\u0119\u0118\u0142\u0141\u0144\u0143\u00f3\u00d3\u015b\u015a\u017a\u0179\u017c\u017b]*$/
);
const polishAlphaNum = helpers.regex(
  "polishAlpha",
  /^[a-zA-Z0-9\u0105\u0104\u0107\u0106\u0119\u0118\u0142\u0141\u0144\u0143\u00f3\u00d3\u015b\u015a\u017a\u0179\u017c\u017b]*$/
);
const polishAlphaNumSpace = helpers.regex(
  "polishAlphaNumSpace",
  /^[a-zA-Z0-9 _\u0105\u0104\u0107\u0106\u0119\u0118\u0142\u0141\u0144\u0143\u00f3\u00d3\u015b\u015a\u017a\u0179\u017c\u017b]*$/
);

export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        username: "",
        password: "",
        passwordRepeat: "",

        phone: "",
        description: "",
        REGON: "",
        NIP: "",

        localNb: "",
        street: "",
        city: "",
        postcode: "",
        country: "",
        policies: "",
      },

      response: {
        status: null,
        content: "",
      },
    };
  },

  methods: {
    register() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        //console.log("Niepoprawnie wypelniony formularz");
        return;
      }
      let payload = {
        company: {
          email: this.form.email,
          username: this.form.username,
          name: this.form.name,
          password: this.form.password,
        },
        details: {
          phone: this.form.phone,
          description: this.form.description,
          REGON: this.form.REGON,
          NIP: this.form.NIP,
        },
        address: {
          local_nb: this.form.localNb,
          street: this.form.street,
          city: this.form.city,
          postcode: this.form.postcode,
          country: "Polska",
        },
      };

      this.$store
        .dispatch("auth/registerCompany", payload)
        .then((response) => {
          if (response.status == 201) {
            this.response = {
              status: true,
              content:
                "Utworzono konto w serwisie App Car. Przejdź do panelu logowania.",
            };
          } else {
            this.response = {
              status: false,
              content:
                "Nie można utworzyć konta w serwisie. Jeśli problem będzie się powtarzał skontaktuj się z administratorem serwisu.",
            };
          }
        })
        .catch((error) => {
          console.error(error);
        });

      //console.log(this.form);
      //console.log("ok");
    },

    hasErrors(vFormField) {
      return vFormField.$invalid && vFormField.$error;
    },

    isNotModified(vFormField) {
      return !vFormField.$invalid && !vFormField.$error;
    },

    getLabelType(vFormField) {
      const labelType = {};

      if (this.hasErrors(vFormField)) {
        labelType["is-danger"] = true;
      } else if (this.isNotModified(vFormField)) {
        labelType["is-success"] = true;
      }

      return labelType;
    },
  },

  validations: {
    form: {
      username: {
        required,
        alphaNum,
      },
      name: {
        required,
        // firstnameValid: helpers.regex("nameValid", /^[a-zA-Z/.& -]+$/i),
        nameValid: polishAlphaNumSpace,
      },

      email: {
        required,
        email,
      },

      password: {
        required,
        minLength: minLength(8),
      },

      passwordRepeat: {
        required,
        sameAsPassword: sameAs("password"),
      },

      city: {
        required,
        // cityValid: helpers.regex("cityValid", /^[a-zA-Z/.& -]+$/i),
        cityValid: polishAlpha,
      },
      street: {
        required,
        // streetValid: helpers.regex("streetValid", /^[a-zA-Z0-9/.& -]+$/i),
        streetValid: polishAlphaNum,
      },
      localNb: {
        required,
        localNbValid: helpers.regex("localNbValid", /^[a-zA-Z0-9/.& -]+$/i),
      },
      postcode: {
        required,
        postcodeValid: helpers.regex("postcodeValid", /^[0-9]{2}-[[0-9]{3}$/i),
      },
      phone: {
        required,
        phoneValid: helpers.regex(
          "phoneValid",
          /(^[0-9]{9}$)|(^[0-9]{11,11}$)/i
        ),
      },

      description: {
        required,
        descriptionValid: helpers.regex(
          "descriptionValid",
          /^[a-zA-Z0-9/.& -]+$/i
        ),
      },
      REGON: {
        required,
        regonValid: helpers.regex("regonValid", /(^[0-9]{9}$)|(^[0-9]{14}$)/i),
      },
      NIP: {
        required,
        nipValid: helpers.regex("nipValid", /^[0-9]{10}$/i),
      },

      policies: {
        required,
      },
    },
  },

  computed: {
    usernameFieldType() {
      return this.getLabelType(this.$v.form.username);
    },

    usernameRules() {
      const errors = [];

      if (!this.hasErrors(this.$v.form.username)) return errors;

      if (!this.$v.form.username.required) {
        errors.push({
          "To pole jest wymagane": true,
        });
      }

      if (!this.$v.form.username.alphaNum) {
        errors.push({
          "Nazwa użytkownika może składać się tylko ze znaków alfanumerycznych": true,
        });
      }

      return errors;
    },

    nameFieldType() {
      return this.getLabelType(this.$v.form.name);
    },

    nameRules() {
      const errors = [];

      if (!this.hasErrors(this.$v.form.name)) return errors;

      if (!this.$v.form.name.required) {
        errors.push({
          "To pole jest wymagane": true,
        });
      }

      if (!this.$v.form.name.nameValid) {
        errors.push({
          "Nazwa firmy może składać się tylko ze znaków alfabetu": true,
        });
      }

      return errors;
    },

    emailFieldType() {
      return this.getLabelType(this.$v.form.email);
    },

    emailRules() {
      const errors = [];

      if (!this.hasErrors(this.$v.form.email)) return errors;

      if (!this.$v.form.email.required) {
        errors.push({
          "To pole jest wymagane": true,
        });
      }

      if (!this.$v.form.email.email) {
        errors.push({
          "Niepoprawny format adresu email": true,
        });
      }

      return errors;
    },

    passwordFieldType() {
      return this.getLabelType(this.$v.form.password);
    },

    passwordRules() {
      const errors = [];

      if (!this.hasErrors(this.$v.form.password)) return errors;

      if (!this.$v.form.password.required) {
        errors.push({
          "To pole jest wymagane": true,
        });
      }

      if (!this.$v.form.password.minLength) {
        errors.push({
          "Hasło musi posiadać conajmniej 8 znaków": true,
        });
      }

      return errors;
    },

    passwordRepeatFieldType() {
      return this.getLabelType(this.$v.form.passwordRepeat);
    },

    passwordRepeatRules() {
      const errors = [];

      if (!this.hasErrors(this.$v.form.passwordRepeat)) return errors;

      if (!this.$v.form.passwordRepeat.required) {
        errors.push({
          "To pole jest wymagane": true,
        });
      }

      if (!this.$v.form.password.sameAsPassword) {
        errors.push({
          "Hasła muszą być takie same": true,
        });
      }

      return errors;
    },

    cityFieldType() {
      return this.getLabelType(this.$v.form.city);
    },

    cityRules() {
      const errors = [];

      if (!this.hasErrors(this.$v.form.city)) return errors;

      if (!this.$v.form.city.required) {
        errors.push({
          "To pole jest wymagane": true,
        });
      }

      if (!this.$v.form.city.cityValid) {
        errors.push({
          "Miejscowość może składać się tylko ze znaków alfabetu, spacji i -": true,
        });
      }

      return errors;
    },

    streetFieldType() {
      return this.getLabelType(this.$v.form.street);
    },

    streetRules() {
      const errors = [];

      if (!this.hasErrors(this.$v.form.street)) return errors;

      if (!this.$v.form.street.required) {
        errors.push({
          "To pole jest wymagane": true,
        });
      }

      if (!this.$v.form.street.streetValid) {
        errors.push({
          "Ulica może się składać tylko ze znaków alfanumerycznych, spacji i -": true,
        });
      }
      return errors;
    },

    localNbFieldType() {
      return this.getLabelType(this.$v.form.localNb);
    },

    localNbRules() {
      const errors = [];

      if (!this.hasErrors(this.$v.form.localNb)) return errors;

      if (!this.$v.form.localNb.required) {
        errors.push({
          "To pole jest wymagane": true,
        });
      }

      if (!this.$v.form.localNb.localNbValid) {
        errors.push({
          "Numer lokalu/domu może się składać tylko ze znaków alfanumerycznych, spacji i -": true,
        });
      }

      return errors;
    },

    postcodeFieldType() {
      return this.getLabelType(this.$v.form.postcode);
    },

    postcodeRules() {
      const errors = [];

      if (!this.hasErrors(this.$v.form.postcode)) return errors;

      if (!this.$v.form.postcode.required) {
        errors.push({
          "To pole jest wymagane": true,
        });
      }
      if (!this.$v.form.postcode.postcodeValid) {
        errors.push({
          "Podaj kod pocztowy w formacie 00-000": true,
        });
      }

      return errors;
    },

    phoneFieldType() {
      return this.getLabelType(this.$v.form.phone);
    },

    phoneRules() {
      const errors = [];

      if (!this.hasErrors(this.$v.form.phone)) return errors;

      if (!this.$v.form.phone.required) {
        errors.push({
          "To pole jest wymagane": true,
        });
      }
      if (!this.$v.form.phone.phoneValid) {
        errors.push({
          "Podaj numer w formacie 48000000000 lub 000000000": true,
        });
      }
      return errors;
    },

    regonFieldType() {
      return this.getLabelType(this.$v.form.REGON);
    },

    regonRules() {
      const errors = [];

      if (!this.hasErrors(this.$v.form.REGON)) return errors;

      if (!this.$v.form.REGON.required) {
        errors.push({
          "To pole jest wymagane": true,
        });
      }
      if (!this.$v.form.REGON.regonValid) {
        errors.push({
          "REGON 9 lub 14 cyfr": true,
        });
      }
      return errors;
    },

    descriptionFieldType() {
      return this.getLabelType(this.$v.form.description);
    },

    descriptionRules() {
      const errors = [];

      if (!this.hasErrors(this.$v.form.description)) return errors;

      if (!this.$v.form.description.required) {
        errors.push({
          "To pole jest wymagane": true,
        });
      }
      if (!this.$v.form.description.descriptionValid) {
        errors.push({
          "Niepoprawnie wypełnione pole": true,
        });
      }
      return errors;
    },

    nipFieldType() {
      return this.getLabelType(this.$v.form.NIP);
    },

    nipRules() {
      const errors = [];

      if (!this.hasErrors(this.$v.form.NIP)) return errors;

      if (!this.$v.form.NIP.required) {
        errors.push({
          "To pole jest wymagane": true,
        });
      }
      if (!this.$v.form.NIP.nipValid) {
        errors.push({
          "NIP składa się z 10 cyfr": true,
        });
      }
      return errors;
    },

    policiesFieldType() {
      return this.getLabelType(this.$v.form.policies);
    },

    policiesRules() {
      const errors = [];

      if (!this.hasErrors(this.$v.form.policies)) return errors;

      if (!this.$v.form.policies.required) {
        errors.push({
          "Musisz zaakceptować regulamin": true,
        });
      }

      return errors;
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-image {
  background-image: url("./../../assets/img/background/bck3.jpg"),
    linear-gradient(#000, #ffffffff);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #999;
  padding: 0;
  padding-top: 1rem;
  min-height: 50vw;
  padding-bottom: 10rem;

  hr {
    width: 90vw;
    height: 0.5rem;
    position: relative;
    left: 5vw;
  }

  .content {
    width: 30vw;
    min-height: 35vh;
    background-color: #ffffffc9;
    border-radius: 15px;
  }

  .header {
    position: relative;
    color: $primary;
    font-weight: 400;
    right: 1rem;
  }

  .container {
    .columns {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}

.card {
  border-radius: 25px;
  background-color: #ffffffaa;

  .input,
  .textarea,
  .taginput .taginput-container.is-focusable,
  .select select {
    background-color: #dddddd;
    border-radius: 20px !important;
  }
}

img {
  bottom: 0.5rem;
}
</style>