<template>
  <div>
    <section>
      <div class="level header">
        <img @click="$router.push('/')"  class="level-left" src="./../assets/img/home/logo.png" />
        <div class="page-subtitle level-right">
          Rejestracja klienta biznesowego
        </div>
      </div>
    </section>
    <div>
      <company-registration-form></company-registration-form>
    </div>
    <section>
      <page-footer></page-footer>
    </section>
  </div>
</template>

<script>
import CompanyRegistrationForm from "@/components/auth/CompanyRegistrationForm";
import Footer from "@/components/common/Footer";

export default {
  components: {
    "company-registration-form": CompanyRegistrationForm,
    "page-footer": Footer,
  },
};
</script>

<style lang="scss">
.header {
  width: 100%;
  height: 10rem;
  background-color: #000;
}

.page-subtitle {
  position: relative;
  right: 2rem;
  color: $primary;
  font-weight: 700;
  font-size: 24pt;
}
</style>